<template>
    <div>
        <span id='header'>The Zen of Cooking</span>
    </div>
</template>

<style lang="scss" scoped>
    #header {
        font-family: 'Kolker Brush', cursive;
        color: rgb(255, 255, 255);
        font-size: 14rem;
        z-index: 2;
        position: fixed;
        width: 100%;
        pointer-events: none;
    }

    @media only screen and (max-width: 576px) {
        #header {
            font-size: 4.5rem;
        }
    }
    @media only screen and (min-width: 577px) and (max-width: 768px) {
        #header {
            font-size: 7rem;
        }
    }
    @media only screen and (min-width: 769px) and (max-width: 992px) {
        #header {
            font-size: 10rem;
        }
    }
    @media only screen and (min-width: 993px) and (max-width: 1200px) {
        #header {
            font-size: 12rem;
        }
    }
</style>