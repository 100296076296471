<template>
    <div class="tile-container">
        <img class="tile" :src="imgPath" alt="food" />
    </div>
</template>

<script>
export default {
  props: {
    imgPath: String,
  }
}
</script>

<style lang="scss" scoped>
    .tile-container {
        display: flex;
        transition: transform .2s;
    }
    .tile-container:hover {
        transform: scale(1.05);
    }
    .tile {
        max-width: calc(100vw / 5);
    }
    @media only screen and (max-width: 780px) {
        .tile {
            max-width: calc(100vw / 3);
        }
    }
    @media only screen and (min-width: 781px) and (max-width: 1200px) {
        .tile {
            max-width: calc(100vw / 4);
        }
    }
</style>