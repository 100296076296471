export const imageArray =[
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    },
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    },
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    },
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    },
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    },
    {
      path: require('../../assets/img/chili-oil-noodles.jpg')
    },
    {
      path: require('../../assets/img/pork-belly-pad-see-ew.jpg')
    },
    {
      path: require('../../assets/img/IMG_0573.jpg')
    },
    {
      path: require('../../assets/img/test2.jpg')
    },
    {
      path: require('../../assets/img/test3.jpg')
    },
    {
      path: require('../../assets/img/test5.jpg')
    }
  ];