<template>
  <TransparentOverlay />
  <TopBanner />
  <div class="flex flex-wrap">
    <div v-for="n in images" :key="n">
      <FoodTile 
        :img-path="n.path"
        @click="this.visibleBottom = true" />
    </div>
  </div>
  <Sidebar v-model:visible="visibleBottom" position="right" class="recipe-modal">
    <div class="flex">
      <div class="recipe-pic">
        <img src="./assets/img/IMG_0544.jpg" />
      </div>
      <div>
        <h2>Right Sidebar</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
    </div>
  </Sidebar>

</template>

<script>
import TopBanner from './components/header/TopBanner.vue'
import Sidebar from 'primevue/sidebar'
import FoodTile from './components/tile/FoodTile.vue'
import TransparentOverlay from './components/overlay/TransparentOverlay.vue'
import { imageArray } from '../src/test/test-data/imageArray'

export default {
  name: 'App',
  components: {
    TopBanner,
    Sidebar,
    FoodTile,
    TransparentOverlay,
  },
  data() {
    return {
      visibleBottom: false,
      images: imageArray
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: black;
  margin: 0;
  padding: 0;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-wrap: wrap;
}
.p-sidebar {
  background-color: black !important;
  color: white !important;
  width: 85vw !important;
  
  .p-sidebar-header {
    display:none;
  }
  .recipe-pic {
    max-height: 96vh;
    img {
      height: calc(100% - 1px);
    }
  }
}
.p-sidebar-content {
  padding: 0 !important;
  margin: 0;
}
.p-sidebar-right .p-sidebar {
  max-height: 96.15vh !important;
}
</style>
