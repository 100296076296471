<template>
    <div class="overlay"></div>
</template>

<style scoped>
    .overlay {
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.3);
        pointer-events: none;
        z-index: 1;
        position: fixed;
     }
</style>